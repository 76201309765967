import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"
import { Helmet } from "react-helmet"

export default function About(props) {
  const { aboutPage } = props.data

  return (
    <Layout {...aboutPage}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>aiKATE | About</title>
        <link rel="canonical" href="https://aikate.eu/about" />
        <meta
          name="About us"
          content="Software development, IoT solutions, About us"
        />
        <meta
          name="description"
          content="Meet our talented team! Our company boasts a team of experienced and dedicated professionals who are passionate about delivering top-notch IT solutions. Get to know our team and learn how we can help your business thrive with our IT services."
        ></meta>
      </Helmet>
      {aboutPage.blocks.map((block) => {
        const { id, blocktype, ...componentProps } = block
        const Component = sections[blocktype] || Fallback
        return <Component key={id} {...componentProps} />
      })}
      <div className="card px-3 py-12 mx-auto sm:max-w-xl rounded rounded-xl  lg:max-w-screen-xl md:px-8 lg:px-4 lg:py-10">
        <div className="max-w-xl sm:mx-auto lg:max-w-xl">
          <div className="flex flex-col mb-10 sm:text-center sm:mb-0">
            <div className="mb-6 sm:mx-auto"></div>
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center sm:max-w-md lg:max-w-xl md:mb-12">
              <h2 className="max-w-lg font-Manrope mb-6  text-6xl font-bold sm:pl-6 leading-none tracking-tight text-white sm:text-4xl lg:text-6xl md:mx-auto">
                Want to join the team?
              </h2>
              <p className="text-base text-white sm:text-center sm:pl-6 md:text-lg">
                Have experience developing desktop or mobile applications?
                Familiar with databases like SQL? Or Java is more of your thing?
                Drop us a message!
              </p>
            </div>
            <div>
              <a
                href="mailto:info@aikate.hr"
                className="contactbutton2 inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md hover:text-opacity-50 focus:shadow-outline focus:outline-none"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    aboutPage {
      id
      title
      description
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        ...AboutHeroContent
        ...AboutStatListContent
        ...HomepageProductListContent
        ...AboutLeadershipContent
        ...HomepageBenefitListContent
        ...AboutLogoListContent
      }
    }
  }
`
